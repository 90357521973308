<template>
  <nav aria-label="Main navigation"
    class="items-center md:w-auto w-full hidden md:flex md:mx-5 md:overflow-x-auto md:overflow-y-visible overflow-x-hidden overflow-y-auto text-default">
    <ul
      class="flex flex-col md:flex-row font-medium md:self-center md:text-[0.9375rem] md:w-auto text-xl tracking-[0.01rem] w-full">
      <li class="dropdown group">
        <button class="flex items-center hover:text-link px-4 py-3">
          <span class="bg-[length:0%_2px] group-hover:bg-[length:100%_2px]">
            Insurance
            <svg class="rtl:ml-0 h-3.5 hidden md:inline ml-0.5 rtl:mr-0.5 w-3.5" data-icon="tabler:chevron-down"
              height="1em" viewBox="0 0 24 24" width="1em">
              <symbol id="ai:tabler:chevron-down">
                <path d="m6 9l6 6l6-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" />
              </symbol>
              <use xlink:href="#ai:tabler:chevron-down"></use>
            </svg>
          </span>
        </button>
        <ul
          class="font-medium drop-shadow-xl dropdown-menu md:absolute md:backdrop-blur-md md:bg-white/90 md:hidden md:min-w-[200px] md:pl-0 pl-4 rounded">
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Home
              Contents</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Credit</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Utilities</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Mobile</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Fitness</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Sports</a>
          </li>
        </ul>
      </li>
      <li class="dropdown group">
        <button class="flex items-center hover:text-link px-4 py-3">
          <span class="bg-[length:0%_2px] group-hover:bg-[length:100%_2px]">
            Support
            <svg class="rtl:ml-0 h-3.5 hidden md:inline ml-0.5 rtl:mr-0.5 w-3.5" data-icon="tabler:chevron-down"
              height="1em" viewBox="0 0 24 24" width="1em">
              <symbol id="ai:tabler:chevron-down">
                <path d="m6 9l6 6l6-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2" />
              </symbol>
              <use xlink:href="#ai:tabler:chevron-down"></use>
            </svg>
          </span>
        </button>
        <ul
          class="font-medium drop-shadow-xl dropdown-menu md:absolute md:backdrop-blur-md md:bg-white/90 md:hidden md:min-w-[200px] md:pl-0 pl-4 rounded">
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">Contact
              Us</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">FAQs</a>
          </li>
          <li>
            <a href="#"
              class="block first:rounded-t hover:text-link last:rounded-b md:hover:bg-gray-100 px-5 py-2 whitespace-no-wrap">About
              Us</a>
          </li>
        </ul>
      </li>
      <li class="group">
        <a role="button" class="flex items-center hover:text-link px-4 py-3" href="#">
          <span class="bg-[length:0%_2px] group-hover:bg-[length:100%_2px]">
            SimplySecure™
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
nav {
  ul {
    li {
      a {
        span {
          @apply bg-left-bottom bg-gradient-to-r from-primary to-primary bg-no-repeat transition-all duration-200 ease-out;
        }
      }

      button {
        span {
          @apply bg-left-bottom bg-gradient-to-r from-primary to-primary bg-no-repeat transition-all duration-200 ease-out;
        }
      }
    }
  }
}
</style>
